import {
    SET_INIT,
    SET_PRODUCT, SET_FEATURES, SET_BUGS, SET_RELEASES,
} from "../definitions/appApiData";

const initState = {current_product:{id:1,title:'Dhru Fusion',article:{id:1,title:'Getting Started',cid:1}}};

export default (state = initState, action) => {

    switch (action.type) {
        case SET_INIT:
            return {
                ...state,
                ...action.data
            };
        case SET_PRODUCT:
            return {
                ...state,
                current_product: {
                    ...state.current_product,
                    ...action.product
                }
            };

        case SET_FEATURES:
            return {
                ...state,
                features: {
                    ...state.features,
                    ...action.features
                }
            };

        case SET_BUGS:
            return {
                ...state,
                bugs: {
                    ...state.bugs,
                    ...action.bugs
                }
            };

        case SET_RELEASES:
            return {
                ...state,
                releases: {
                    ...state.releases,
                    ...action.release
                }
            };

        default:
            return state;
    }
}
