import React, {Component,Fragment} from "react";

export class Footer extends Component {
    render() {

        const year = new Date().getFullYear();

        return (
            <Fragment>
                <div className={'p-4 bg-footer text-center'}>
                    <span className="__bullet">&copy; Dhru Cloud Pvt Ltd {year}</span>
                    <span className="__bullet">Dhru™ </span>
                    <span className="__bullet">Smart Lifestyle™ </span>
                    <span className="__bullet">Dhru Cloud™ </span>
                    <span className="__bullet">POS.WORK™</span>
                </div>
            </Fragment>
        );
    }
}
export default  Footer;
