import React, {Component } from 'react'

class Index extends Component {


    render() {
        //let randomColor = Math.floor(Math.random()*16777215).toString(16);
        return (
            <div   className={'profile-picture'}>
                {this.props.dp}
            </div>
        )
    }
}

export default Index
