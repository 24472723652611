import React, {Component, Fragment} from "react";
import {
    Nav, Navbar, NavbarBrand, NavItem,
    NavLink,
} from 'reactstrap';
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {checkUserAccess, popupLogin} from "../../lib/functions";
import {Links} from "./Links";
import Companies from "./Companies";
import {ReactComponent as Logo} from "../../assets/images/logo.svg";


export class Index extends Component {


    handlerSetDialogLogin = () => {
        popupLogin();
    };

    render() {
        const {isAuthenticated,setDialog,products,current_product,category,version} = this.props;
        return (
            <Fragment>
                <Navbar light  expand="lg" fixed="top" className={'justify-content-start'}>
                    <NavbarBrand className={'mr-0'} href="/"  style={{width:'40px'}}>  <Logo/>    </NavbarBrand>

                    {current_product &&  products  &&  <Companies products={products}   current_product={current_product}  /> }

                </Navbar>
                <Nav className="mt-4 ml-2" navbar>

                    <Links setDialog={setDialog} products={products} category={category} version={version}  current_product={current_product}/>

                    <NavItem className={'d-block d-lg-none'}>
                        {isAuthenticated &&
                        <NavLink className={'cursor-pointer'} onClick={() => checkUserAccess(false)}>
                            Logout
                        </NavLink>
                        }
                        {!isAuthenticated &&
                        <NavLink  className={'cursor-pointer'} onClick={()=>this.handlerSetDialogLogin()}>
                            Login
                        </NavLink>
                        }
                    </NavItem>

                </Nav>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    user: state.authentication.authDetails.user,
    category:state.appApiData.category,
    version:state.appApiData.version
});

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
