import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {
    NavLink,
    } from 'reactstrap';
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {validUrl} from "../../lib/functions";


export class Index extends Component {

    render() {
        const {isAuthenticated,user,current_product} = this.props;
        return (
            <div>
                {isAuthenticated && user.staff && <div>
                    <NavLink tag={Link} to={`/${validUrl(current_product.title)}/manageversion/?pid=${current_product.id}`}><strong> Manage Version </strong></NavLink>
                </div>}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    user: state.authentication.authDetails.user,
    current_product : state.appApiData.current_product
});

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
