const SET_INIT = "SET_INIT";
const SET_PRODUCT = "SET_PRODUCT";
const SET_FEATURES = "SET_FEATURES";
const SET_BUGS = "SET_BUGS";
const SET_RELEASES = "SET_RELEASES";


export {
    SET_INIT,
    SET_PRODUCT,
    SET_FEATURES,
    SET_BUGS,
    SET_RELEASES
};
