import React, {Component} from "react";
import {connect} from "react-redux";
import {analyticIntercom, map} from "../../lib/functions";
import {setDialog} from "../../lib/Store/actions/components";
import {Badge, Button} from "reactstrap";
import AddEditVersion from "../AddEditVersion";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {adminUrl} from "../../lib/setting";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state={
            releases : false
        }
    }

    componentDidMount() {
        analyticIntercom();
        const {current_product} = this.props
        requestApi({
            method: methods.get,
            action: actions.version,
            queryString:{pid:current_product.id},
            otherurl:adminUrl
        }, (response) => {
            if(response.status === SUCCESS){
                this.setState({releases:response.data});
            }
        })
    }

    addEditVersion = (version) => {
        const {setDialog} = this.props;
        setDialog({
            show: true,
            title:version.id?'Edit Version':'Add New Version',
            maxWidth: "lg",
            fullScreen: true,
            className:'modal-version',
            component: () => <AddEditVersion  data={version}  />
        });
    }



    render() {

        const {releases} = this.state;
        const that = this;

        return (
            <div className={'page-container'}>
                <div className={'container'}>
                    <div className={''}>

                   <div className={'d-flex center'}>
                        <h1 className="title"> Manage Version </h1>
                        <input type="button" value="Add" className="btn btn-outline-secondary" onClick={()=>{this.addEditVersion({id:''})}} />
                    </div>


                    {releases && <div>
                        <div className={'py-3'}>
                            <div className={'table-responsive'}>
                                <table className={'table table-bordered'}>
                                <thead>
                                    <tr>
                                        <th>Version</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Display</th>
                                        <th>Last Released</th>
                                        <th></th>
                                    </tr>
                                </thead>


                                {
                                    releases &&  map(releases, function (key, version) {
                                        return(
                                            <tbody  key={key}>
                                                <tr>
                                                    <td>{version.version}</td>
                                                    <td>{version.date}</td>
                                                    <td>  <Badge className={`badge mt-2 text-uppercase  badge-${version.status}`}> {version.status} </Badge>  </td>
                                                    <td>{version.display?'Yes':''}</td>
                                                    <td>{version.releasedate !== '0000-00-00'?version.releasedate:''}</td>
                                                    <td><Button color="secondary" size={'sm'} onClick={()=>{that.addEditVersion(version)}}>Edit</Button></td>
                                                </tr>

                                                {
                                                    version.sub &&  map(version.sub, function (key, version) {
                                                        return(
                                                            <tr key={key}>
                                                                <td> &nbsp;&nbsp;&nbsp; <span className={'text-muted'}>{version.version} </span> </td>
                                                                <td>{version.date}</td>
                                                                <td>  <Badge className={`badge mt-2 text-uppercase  badge-${version.status}`}> {version.status} </Badge>  </td>
                                                                <td>{version.display?'Yes':''}</td>
                                                                <td>{version.releasedate !== '0000-00-00'?version.releasedate:''}</td>
                                                                <td><Button color="secondary" size={'sm'} onClick={()=>{that.addEditVersion(version)}}>Edit</Button></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>)
                                    })
                                }

                            </table>
                            </div>
                        </div>
                    </div>}

                </div>
            </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        releases: state.appApiData.releases,
        isAuthenticated: state.authentication.isAuthenticated,
        user: state.authentication.authDetails.user,
        current_product: state.appApiData.current_product,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
