import React, {Component, useEffect, useState} from "react";
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownIt from 'markdown-it';
import markdownItTocAndAnchor from "markdown-it-toc-and-anchor";
import {connect} from "react-redux";
import {Button} from "reactstrap";
import {setDialog} from "../../lib/Store/actions/components";
import AddEditArticle from "../AddEditArticle";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil } from '@fortawesome/pro-light-svg-icons';
import {adminUrl} from "../../lib/setting";
import {map, uploadFile, scrollWidthOffset, searchParamsToObj} from "../../lib/functions";
import { HashLink as Link } from 'react-router-hash-link';
import {useParams} from "react-router-dom";


const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
});
let toc;
mdParser.use(markdownItTocAndAnchor, {
    toc:true,
    tocClassName:'toc-navigation',
    tocCallback: function(tocMarkdown, tocArray, tocHtml) {
        //console.log(tocArray);
        toc=tocArray;
    }
})


const Index = (props) => {

    const params = useParams();

    let aid = searchParamsToObj('aid')
    const [articledetail,setArticledetail] = useState(false)
    const [updatemode,setUpdatemode] = useState(false)



    let editor = React.createRef()

    useEffect(()=>{
        if(!aid){
            aid = 1
        }
        const hash = window.location.hash;
        requestApi({
            method: methods.get,
            action: actions.article,
            queryString: {articleid: aid}
        }, (response) => {
            if (response.status === SUCCESS) {
                const {current_product} = props;
                document.title = current_product.title + ' - ' + response.data.title;
                setArticledetail(response.data)
                if(hash) {
                    setTimeout(function () {
                        scrollWidthOffset(document.querySelector(`${hash}`));
                    }, 100)
                }
            }
        })
    },[aid])


    const onSubmit = () => {
        requestApi({
            method: methods.put,
            action: actions.article,
            body: {...articledetail},
            otherurl:adminUrl
        }, (response) => {
            if (response.status === SUCCESS) {
                setUpdatemode(false)
            }
        });
    };

    const editArticle = (data) => {
        const {setDialog} = props;
        setDialog({
            show: true,
            title:'Edit Article',
            maxWidth: "lg",
            fullScreen: true,
            className:'modal-article',
            component: () => <AddEditArticle data={data}  />
        });
    }

    const handleChange = (content) => {
        setArticledetail({...articledetail,info:content.text})
    }


    const handleImageUpload = (file, callback) => {
        const reader = new FileReader()
        reader.onload = () => {
            uploadFile(file, function (response) {
                callback(response.download_url);
            });
        }
        reader.readAsDataURL(file)
    }

    const {user,isAuthenticated,current_product} = props;

    const plugins = ['header',
        'font-bold',
        'font-italic',
        'font-underline',
        'font-strikethrough',
        'list-unordered',
        'list-ordered',
        'block-quote',
        'block-wrap',
        'block-code-inline',
        'block-code-block',
        'table',
        'image',
        'link',
        'clear',
        'logger',
        'mode-toggle',
        'full-screen'];

    return (
        <div>
            {articledetail &&  <div>

                <div className={''}>


                    {isAuthenticated && user.staff && updatemode ?
                        <div>

                            <h1 className="title w-100">{articledetail.title}</h1>
                            <MdEditor
                                value={articledetail.info}
                                ref={editor}
                                style={{height: '800px'}}
                                renderHTML={(text) => mdParser.render(text)}
                                plugins={plugins}
                                onChange={handleChange}
                                onImageUpload={handleImageUpload}
                            />


                            <div className={'center mt-3'}>
                                <div>
                                    <Button color="primary" onClick={()=>onSubmit()}>Save</Button>
                                    <Button color="secondary ml-3" onClick={()=> setUpdatemode(!updatemode) }>  Cancel </Button>
                                </div>
                                <input type="button" size={'sm'} value="Edit Article" className="btn btn-outline-secondary" onClick={()=>editArticle(articledetail)}   />
                            </div>
                        </div>  :

                        <div className={'row m-0 react-mark-down'}> <div className={'col'}>

                            <div className={'center'}>
                                <h1 className="title">{articledetail.title}</h1>
                                {isAuthenticated && user.staff &&
                                    <Button color="transparent" onClick={()=> setUpdatemode(!updatemode) }> <FontAwesomeIcon icon={faPencil} /> </Button>
                                }
                            </div>

                            <div
                                dangerouslySetInnerHTML={{
                                    __html: mdParser.render(articledetail.info)
                                }}></div>   </div>


                            {Boolean(toc.length) &&  <div className={'col-lg-3 ml-lg-4 d-none d-lg-block toc-navigation'}>
                                <ul>
                                    {
                                        map(toc, function (key, hash) {
                                            return (
                                                <li key={key} >
                                                    <Link className={`nav-link level-${hash.level}`}  scroll={(el)=> scrollWidthOffset(el)}     to={{hash: `#${hash.anchor}`,search: `?aid=${articledetail.id}&pid=${current_product.id}&cid=${articledetail.cid}`}}>
                                                        {hash.content}
                                                    </Link>

                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>}
                        </div>
                    }

                </div>

            </div>}
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        user: state.authentication.authDetails.user,
        current_product : state.appApiData.current_product
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
