import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import configureStore from './lib/Store/configureStore'
import Loader from "./components/Loader/loader"
import Loader2 from "./components/Loader/loader2"
import {SnackbarProvider} from "notistack";
import RoutingComponent from "./lib/RoutingComponent";
import Dialog from "./components/Modal";
//import Notifier from "./components/Notifier";
import {createBrowserHistory} from "history";
import './theme/style.scss';
import Navigation from "./pages/Navigation";
import Footer from "./pages/Navigation/Footer";
import {getInit} from "./lib/functions";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

JavascriptTimeAgo.addLocale(en);




export const store = configureStore();

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});


const JSX = () => {

    return (
        <Provider store={store}>
            <GoogleReCaptchaProvider reCaptchaKey="6LebDscZAAAAAEfuz_E-rB6r12xLHekW_XlR-Y5V" useRecaptchaNet={true}>
            <SnackbarProvider
                maxSnack={1}
                autoHideDuration={1000}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
                <BrowserRouter history={history}>
                    <Navigation />
                    <RoutingComponent />
                    <Footer/>
                    <Dialog/>
                </BrowserRouter>

                <Loader2/>

                <ToastContainer  position="top-center"
                                 autoClose={2000}
                                 hideProgressBar={true}
                                 newestOnTop={false}
                                 closeOnClick
                                 rtl={false}
                                 pauseOnFocusLoss
                                 draggable
                                 pauseOnHover
                                 theme="light" />

            </SnackbarProvider>
            </GoogleReCaptchaProvider>
        </Provider>
    )
};

//localStorage.clear();
console.clear();

getInit()


const root = ReactDOM.createRoot(document.getElementById("root"));
export const render = () => {
    root.render(
        <JSX />
    );
};

render()

/*ReactDOM.render(<Loader/>, document.getElementById('root'));
export const render = () => ReactDOM.render(<JSX/>, document.getElementById('root'));*/

serviceWorker.unregister();





