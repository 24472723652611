import React, {Component, Fragment} from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavLink,
    NavItem, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, InputGroupAddon,
} from 'reactstrap';
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import { InputGroup, InputGroupText,Input } from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {checkUserAccess, getHighlightedText, map, popupLogin, validUrl} from "../../lib/functions";
import {Links} from "./Links";
import Sidebar from "./Sidebar";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {Link} from "react-router-dom";
import Companies from "./Companies";
import {faBug, faFile, faLightbulbOn, faSearch, faSignInAlt, faSignOutAlt} from "@fortawesome/pro-light-svg-icons";
import {faUser} from "@fortawesome/pro-light-svg-icons/faUser";
import {ReactComponent as Logo} from "../../assets/images/logo.svg";

export class Navigation extends Component {
    constructor(props) {
        super(props);


        this.toggleNav = this.toggleNav.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.state = {
            isOpen: false,
            dropdownOpen:false,
            searchlist:undefined
        };
    }
    toggleNav() {
        const {setDialog,products,current_product} = this.props;
        setDialog({
            show: true,
            title:'',
            maxWidth: "md",
            fullScreen: true,
            className:'modal-menu left',
            component: () => <Sidebar setDialog={setDialog} products={products} current_product={current_product}/>
        });
    }
    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    handlerSetDialogLogin = () => {
        popupLogin();
    };


    searchData(e) {
        let searchText = e.target.value;
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if(searchText !== '') {
                requestApi({
                    method: methods.get,
                    action: actions.search,
                    queryString:{keyword:searchText},
                }, (response) => {
                    if(response.status === SUCCESS){
                        this.setState({searchlist:response.data,search:searchText});
                    }
                })
            }
            else{
                this.setState({searchlist:undefined});
            }
        },500);
    }




    render() {

        const {isAuthenticated,setDialog,current_product,products,user,category,version} = this.props;
        const {searchlist} = this.state;
        const that = this;

        return (
            <div>
                <Navbar light  expand="lg" fixed="top" className={'pl-0   pl-lg-3 pr-lg-3'}>
                    <NavbarToggler onClick={this.toggleNav} />
                    <NavbarBrand className="mr-auto" style={{width:'40px'}}>  <Logo/>    </NavbarBrand>
                    <Collapse   navbar>
                        <Nav className="mr-auto" navbar>

                            {current_product &&  products  &&  <Companies products={products}   current_product={current_product}  /> }

                            <Links setDialog={setDialog} products={products} category={category} version={version}  current_product={current_product}/>
                        </Nav>
                    </Collapse>

                    <Nav  className={'search-bar'}>

                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><FontAwesomeIcon icon={faSearch} /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input className={'search-input'}   onChange={(event) => this.searchData(event)}  placeholder={'Search...'} />
                                </InputGroup>
                            </DropdownToggle>

                            {(searchlist) ? <Fragment>
                                {Boolean(searchlist.length) &&
                                        <DropdownMenu className={'search-result'} right>

                                            <div className={'search-result-title'}>Search Result</div>
                                             <div className={'overflow'}>
                                                { map(searchlist,function(key,search) {
                                                    return <DropdownItem key={key}>
                                                        <div className={'d-flex align-items-center justify-content-start'}  >
                                                            <div className={'text-muted text-uppercase mr-3'}>
                                                                {search.type==='feature' && <FontAwesomeIcon icon={faLightbulbOn} color={"#159e05"}   />}
                                                                {search.type==='bug' && <FontAwesomeIcon icon={faBug} color={"#e60000"}   />}
                                                                {search.type==='article' && <FontAwesomeIcon icon={faFile} color={"black"}   />}
                                                                 &nbsp; {search.type}
                                                            </div>
                                                            <div className={'search-text'}>
                                                                {search.type === 'article' ?
                                                                    <NavLink tag={Link} to={`/${validUrl(current_product.title)}/article/${validUrl(search.link)}/?aid=${search.id}&pid=${current_product.id}`}>  {getHighlightedText(search.title,that.state.search)} </NavLink> :
                                                                    <NavLink tag={Link} to={`/${validUrl(current_product.title)}/featuredetail/${validUrl(search.link)}/?fid=${search.id}&pid=${current_product.id}`}> {getHighlightedText(search.title,that.state.search)} </NavLink>}
                                                            </div>
                                                        </div>
                                                    </DropdownItem>
                                                })}
                                             </div>
                                        </DropdownMenu>
                                      }

                            </Fragment> : null  }
                        </UncontrolledDropdown>


                    </Nav>

                    <Nav>



                        <NavItem  className={'d-none d-lg-block'}>
                            {isAuthenticated &&
                                <UncontrolledDropdown>
                                    <DropdownToggle nav>
                                        <FontAwesomeIcon icon={faUser}   />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div className={'p-3'}>
                                            <strong>{user.firstname} {user.lastname}</strong>
                                            <div> <small>{user.email}</small> </div>
                                        </div>

                                        <NavLink className={'cursor-pointer'} onClick={() => checkUserAccess(false)}>
                                            <FontAwesomeIcon icon={faSignOutAlt}   /> Logout
                                        </NavLink>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            }
                            {!isAuthenticated &&
                            <NavLink  className={'cursor-pointer'} onClick={()=>this.handlerSetDialogLogin()}>
                                <FontAwesomeIcon icon={faSignInAlt}  />
                            </NavLink>
                            }
                        </NavItem>
                    </Nav>



                </Navbar>
            </div>
        );
    }
}




const mapStateToProps = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    user: state.authentication.authDetails.user,
    products: state.appApiData.product,
    current_product:state.appApiData.current_product,
    category:state.appApiData.category,
    version:state.appApiData.version
});

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
