import React, {Component} from "react";
import {setInit} from "../../lib/Store/actions/appApiData";
import {connect} from "react-redux";
import {searchParamsToObj,analyticIntercom} from "../../lib/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList } from '@fortawesome/pro-light-svg-icons';
import {setDialog} from "../../lib/Store/actions/components";
import ArticleDetail from "./ArticleDetail";
import CategoryList from "./CategoryList";


class Index extends Component {


    handlerSetCatelogList = () => {
        const {setDialog,category} = this.props;
        setDialog({
            show: true,
            title:'',
            maxWidth: "md",
            fullScreen: true,
            className:'modal-catloglist right',
            component: () => <CategoryList category={category} />
        });
    };

    componentDidMount() {
        analyticIntercom();
    }


    render() {

        let path = window.location.href;

        const cid = searchParamsToObj('cid');
        const aid = searchParamsToObj('aid');

       // const article = (path.includes('article') && (aid || cid)) || (!path.includes('article'));


        return (

            <div className={"page-container"}>
                <div><div className={'row m-0 no-wrap'}>
                    <div className={'col-lg-3 border-right sidebar d-none d-lg-block'}>
                        <CategoryList  cid={cid} aid={aid} />
                    </div>
                    <div className={'flex-grow  page-content'}>
                        <div className={'container'}>
                            <div>
                                <ArticleDetail aid={aid} />
                                <div className={'bottom-icon d-block d-lg-none'} onClick={this.handlerSetCatelogList}>
                                    <FontAwesomeIcon icon={faList} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>



        );
    }
}

const mapStateToProps = (state) => {
    return {
        category: state.appApiData.category,
        current_product:state.appApiData.current_product,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setInit: (category) => dispatch(setInit(category)),
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
