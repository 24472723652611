import React, {Component } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/pro-light-svg-icons";


class Content extends Component {
    constructor (props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e) {
        this.refs.fileUploader.click();
    }
    render() {
        return (
            <div className="file-uploader">
                <div   onClick={this.handleClick.bind(this)} className={'cursor-pointer p-2'}>
                    <FontAwesomeIcon icon={faPaperclip}   /> <small> Attach File</small>
                    <input label='upload file' type='file' onChange={this.props.handleFileUpload} id="file" ref="fileUploader"  style={{display: "none"}} />
                </div>
            </div>
        )
    }
}

export default Content
