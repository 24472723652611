import React, {Component} from "react";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {map} from "../../lib/functions";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {adminUrl} from "../../lib/setting";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state={"id":'',"pid":props.current_product.id,releasedate:'',mid:'0',status:'Upcoming',version:'',display:'',lastrelease:'',summary:"",featurelist:""}
    }

    componentDidMount() {
        if(this.props.data){
            this.setState({...this.props.data});
        }
    }

    onSubmit = () => {
        const {id} = this.state;
        const {setDialog} = this.props;
        requestApi({
            method: id?methods.put:methods.post,
            action: actions.version,
            body: {...this.state},
            otherurl:adminUrl
        }, (response) => {
            if (response.status === SUCCESS) {
                setDialog({
                    show: false,
                });
            }
        });
    };


    render() {


        const {releasedate,status,version,mid,pid} = this.state;
        const {versions,products} = this.props;

        return (
            <div>
                <Form   noValidate>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Version</Label>
                                <Input type="text" name={'version'} value={version} onChange={(event) => this.setState({version:event.target.value})} className={'form-control'} placeholder={'Version'}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Release Date</Label>
                                <Input type="text" name={'releasedate'} value={releasedate} onChange={(event) => this.setState({releasedate:event.target.value})} className={'form-control'} placeholder={'Release Date'}/>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="exampleAddress">Products</Label>
                        <Input type="select" name="pid" value={pid}  onChange={(event) => this.setState({pid:event.target.value})}>
                            {
                                map(products, function (key, product) {
                                    return(
                                        <option key={key} value={product.id}>{product.title}</option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Status</Label>
                                <Input type="select" name="status" value={status}  onChange={(event) => this.setState({status:event.target.value})}>
                                    <option value="Upcoming">Upcoming</option>
                                    <option value="Beta">Beta</option>
                                    <option value="Released">Released</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Listed In</Label>
                                <Input type="select" name="mid" value={mid}  onChange={(event) => this.setState({mid:event.target.value})}>
                                    <option  value="0">Main</option>
                                    {
                                        map(versions, function (key, version) {
                                            return(<option key={key} value={version.id}>{version.version}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup check className={'mb-3'}>
                        <Label check><Input onChange={(event) => this.setState({display:event.target.checked?1:0})} type="checkbox"   />  Display</Label>
                    </FormGroup>

                    <FormGroup check className={'mb-3'}>
                        <Label check><Input onChange={(event) => this.setState({lastrelease:event.target.checked?1:0})} type="checkbox"   />  Last Release</Label>
                    </FormGroup>


                    <FormGroup className={'mt-3'}>
                        <Button color="primary" onClick={()=>this.onSubmit()}>Save</Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        category: state.appApiData.category,
        products: state.appApiData.product,
        current_product: state.appApiData.current_product,
        user: state.authentication.authDetails.user,
        versions: state.appApiData.version,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
