import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {addEditBugFeature, map, validUrl} from "../../lib/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {  faSortAlt,faLightbulbOn,faBug,faAngleUp,faAngleDown,faPencil } from '@fortawesome/pro-light-svg-icons';
import {setDialog} from "../../lib/Store/actions/components";
import AddEditCategory from "../AddEditCategory";
import AddEditArticle from "../AddEditArticle";


import 'react-sortable-tree/style.css';
import SortableTree from 'react-sortable-tree';
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {adminUrl} from "../../lib/setting";


import {NavLink} from "reactstrap";
import {Link} from "react-router-dom";


class Index extends Component {

    constructor(props) {
        super(props);
        this.state={
            category:props.category,
            sorting:false,
            collapse: false,
            selected:props.cid?props.cid:props.current_product.article.cid,
            aid:props.aid?props.aid:props.current_product.article.id
        }
        this.toggle = this.toggle.bind(this);
        this.selectedArticle = this.selectedArticle.bind(this);
    }

    componentDidMount() {
        this.setState({selected:this.props.cid});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({category:nextProps.category,selected:nextProps.cid});
    }




    editCategory(category){
        const {setDialog} = this.props;
        setDialog({
            show: true,
            title:'Edit Category',
            maxWidth: "lg",
            fullScreen: true,
            className:'modal-category',
            component: () => <AddEditCategory data={category} />
        });
    }

    addCategory(){
        const {setDialog} = this.props;
        setDialog({
            show: true,
            title:'Add New Category',
            maxWidth: "lg",
            fullScreen: true,
            className:'modal-category',
            component: () => <AddEditCategory   />
        });
    }

    addArticle(){
        const {setDialog} = this.props;
        setDialog({
            show: true,
            title:'Add New Article',
            maxWidth: "lg",
            fullScreen: true,
            className:'modal-article',
            component: () => <AddEditArticle   />
        });
    }

    sortArticle(){
        this.setState({sorting:!this.state.sorting}  );
    }


    saveSortArticle(){
        requestApi({
            method: methods.put,
            action: actions.orders,
            body: {...this.state.category},
            otherurl:adminUrl
        }, (response) => {
            if (response.status === SUCCESS) {
                this.setState({sorting:false});
            }
        });
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    selectedArticle = (aid) => {
        this.setState({aid:aid});
    }


    render() {

        let {isAuthenticated,user,current_product,setDialog} = this.props;
        let {sorting,selected,aid,category} = this.state;

        const that = this;

        return (
            <Fragment>
                {sorting ? <Fragment><SortableTree
                        treeData={category}
                        maxDepth={2}
                        onChange={category => this.setState({ category })}
                    /> <div className={'p-2 d-flex'}> <button type={'button'}  className={'btn w-50 btn-success'} onClick={()=>{this.saveSortArticle()}}> Save </button> <button type={'button'}  className={'btn w-50 btn-outline-secondary'} onClick={()=>{this.sortArticle()}}> Cancel </button> </div> </Fragment>
                 :
                    <div>
                        <div className={'p-2'}>
                            <div className={'p-2 d-flex '}>
                                <button type={'button'} className={'btn mr-2 w-50 btn-outline-success'} onClick={()=>{addEditBugFeature({id:'',type:'feature'})}}><FontAwesomeIcon icon={faLightbulbOn}   /> Post Idea</button>
                                <button type={'button'}  className={'btn w-50 btn-outline-danger'} onClick={()=>{addEditBugFeature({id:'',type:'bug'})}}><FontAwesomeIcon icon={faBug}  /> Post Bug</button>
                            </div>
                            <ul className={'list-group border-0'}>
                                {isAuthenticated && user.staff && <Fragment>
                                    <li className={'list-group-item py-0 px-2 mb-3'}>
                                        <div onClick={()=>this.addCategory()}  className="p-2 mr-2 btn btn-outline-secondary">
                                            +  category
                                        </div>
                                        <div onClick={()=>this.addArticle()}  className="p-2 mr-2 btn btn-outline-secondary">
                                            +  Article
                                        </div>
                                        {/*<div onClick={()=>this.sortArticle()}  className="p-2 btn btn-outline-secondary">
                                            <FontAwesomeIcon icon={faSortAlt}   />
                                        </div>*/}
                                    </li>
                                </Fragment>}
                                {
                                    category && map(category, function (key, cat) {
                                        return (
                                            <li className={'list-group-item   align-items-center p-0'} key={key}>
                                                {cat.title &&    <div className={'center'}>
                                                    <div className={'nav-link nav-link-category center w-100'} onClick={()=> that.setState({selected:cat.id})}>  <span className={`${cat.id === +selected?'selected':''}`}>{cat.title}  </span>   {isAuthenticated && user.staff && <div onClick={()=>that.editCategory(cat)}  className="on-hover">
                                                        <FontAwesomeIcon icon={faPencil} />
                                                    </div>}
                                                    <div> {cat.id === +selected ? <FontAwesomeIcon icon={faAngleUp} size={'2x'} /> : <FontAwesomeIcon icon={faAngleDown} size={'2x'} /> }  </div>
                                                    </div>
                                                </div> }

                                                {cat.children &&  <ul className={`pl-2 ${cat.id === +selected?'':'collapse'}`}>
                                                    {
                                                        map(cat.children, function (key, article) {
                                                            return (
                                                                <li key={key}  className={'list-group-item  align-items-center p-0'} >
                                                                    {article.title &&  <div onClick={()=>setDialog({show:false})}>
                                                                       <div onClick={()=> that.selectedArticle(article.id) } >  <NavLink tag={Link} className={+aid  === article.id?'active': '' } to={`/${validUrl(current_product.title)}/article/${validUrl(article.title)}/?aid=${article.id}&cid=${selected}&pid=${current_product.id}`}>
                                                                            <div className={'w-100'}> {article.title}  </div>
                                                                        </NavLink></div>
                                                                    </div> }
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>}

                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        user: state.authentication.authDetails.user,
        current_product : state.appApiData.current_product,
        category: state.appApiData.category,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
