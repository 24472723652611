const products = [
    {value: "1", label: "Dhru Fusion",seo:'Dhru_Fusion'},
    {value: "2", label: "Dhru ERP",seo:'Dhru_ERP'},
];
const features_status = [
    {value: "unconfirmed", label: "Unconfirmed"},
    {value: "completed", label: "Completed"},
    {value: "declined", label: "Declined"},
    {value: "review", label: "Review"},
];
const bugs_status = [
    {value: "unconfirmed", label: "Unconfirmed"},
    {value: "completed", label: "Completed"},
    {value: "notabug", label: "Not a Bug"},
    {value: "review", label: "Review"},
];

export {
    products,
    features_status,
    bugs_status
}
