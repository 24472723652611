import React, {Component,Fragment} from "react";
import {
    NavLink,
    DropdownToggle, DropdownMenu, UncontrolledDropdown,
} from 'reactstrap';
import {validUrl, map, getInit,filterObject} from "../../lib/functions";
import {setDialog} from "../../lib/Store/actions/components";
import {connect} from "react-redux";



export class Index extends Component {

    constructor(props) {
        super(props);
        this.state={
            filterproduct:2
        }
    }


    hideMenu = () => {
        const {setDialog} = this.props;
        setDialog({
            show: false,
        });
        setTimeout(function () {
            getInit();
        },100)
    };

    componentDidMount() {
        const {isAuthenticated,user} = this.props;
        if(!isAuthenticated && !user.staff){
            let length = filterObject(this.props.products,'status','1').length;
            this.setState({filterproduct: length > 1 ? length : 1 });
        }
    }

    render() {
        const {current_product,products,isAuthenticated,user} = this.props;
        const {filterproduct} = this.state;
        const that = this;
        return (
            <Fragment>
                {(filterproduct > 1 || (isAuthenticated && user.staff) )  ?  <UncontrolledDropdown>
                    <DropdownToggle nav  className={'nav-product'}>
                        {current_product.title}
                    </DropdownToggle>
                   <DropdownMenu>
                        {
                            map(products, function (key, product) {
                                return ( <Fragment key={key}> {(product.status === '1' || (isAuthenticated && user.staff)) && <NavLink    onClick={that.hideMenu} href={`/${validUrl(product.title)}/article/${validUrl(product.article.title)}/?aid=${product.article.id}&cid=${product.article.cid}&pid=${product.id}`}   > {product.title} </NavLink>} </Fragment> )
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown> : <span className={'nav-product nav-link'} > {current_product.title} </span> }
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    user: state.authentication.authDetails.user,
});

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});

export default connect(mapStateToProps,mapDispatchToProps)(Index);
