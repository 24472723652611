import React, {Component,Fragment} from "react";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {Col, Row, Button, Form, FormGroup, Label, Input, NavLink} from 'reactstrap';
import {setDialog} from "../../lib/Store/actions/components";
import {connect} from "react-redux";
import {getHighlightedText, map, uploadFile, validUrl} from "../../lib/functions";
import {adminUrl} from "../../lib/setting";
import FileUploader from "../../components/FileUploader";
import FileIcon from "../../components/FileIcon";
import {Link} from "react-router-dom";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state={id:'',title:"",content:"",searchResult:false,type:props.data.type,version:props.versions[0].version,vote:1,uid:props.user.uid,pid:props.current_product.id,hot:'0',priority:'medium',status:'unconfirmed',files:[]}
    }

    componentDidMount() {
        const {data} = this.props;
        let feature;
        if(data.content){
            data.content = atob(data.content);
        }
        if(data.id){
            feature = {title:data.title,uid:data.uid,content:data.content,type:data.type,pid:data.pid,version:data.version,priority:data.priority,status:data.status,id:data.id,files:data.files}
        }
        this.setState({...feature});
    }

    onSubmit = () => {
        const {id,status,version} = this.state;
        const {setDialog} = this.props;
        if(status === 'completed'){
            this.setState({completedversion:version});
        }
        requestApi({
            method: id?methods.put:methods.post,
            action: actions.features,
            body: {...this.state},
            otherurl:id?adminUrl:''
        }, (response) => {
            if (response.status === SUCCESS) {
                setDialog({
                    show: false,
                });
            }
        });
    };


    handleFileUpload = (event) => {
        const that = this;
        const file = event.target.files[0];
        if(file.size < 186000) {
            this.setState({
                files: [...this.state.files, {download_url: '', file_name: ''}]
            });
            uploadFile(file, function (response) {
                let last = that.state.files.length - 1;
                let array = [...that.state.files];
                array[last] = response;
                that.setState({
                    files: array
                })
            });
        }
        else{
            const {setDialog} = this.props;
            setDialog({
                show: true,
                title: '',
                maxWidth: "lg",
                fullScreen: true,
                className: 'modal-image',
                component: () =>  <div className={'p-5'}><h6>Can't upload more than 10MB</h6></div>
            });
        }
    }

    searchFeature = (e) => {
        let searchText = e.target.value;
        this.setState({title: searchText});
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if(searchText !== '') {
                requestApi({
                    method: methods.get,
                    action: actions.features,
                    queryString:{search:searchText,type:this.state.type},
                    alert:false
                }, (response) => {
                    if(response.status === SUCCESS){
                         this.setState({searchResult:response.data});
                    }
                })
            }
            else{
                this.setState({searchResult:false});
            }
        },500);
    }

    removeAttachment = (index) =>{
        let array = [...this.state.files];
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({files: array});
        }
    }

    render() {

        const {title,content,type,version,priority,status,id,files,pid,searchResult} = this.state;
        const {versions,products,current_product,setDialog} = this.props;
        const that = this;

        return (
            <div>
                <Form   noValidate >

                    <FormGroup>
                        <Label for="exampleAddress">Products</Label>
                        <Input type="select" name="pid" value={pid}  onChange={(event) => this.setState({pid:event.target.value})}>
                            {
                                map(products, function (key, product) {
                                    return(
                                        <option key={key} value={product.id}>{product.title}</option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>


                    <FormGroup className={'position-relative'}>
                        <Label>Title</Label>
                        <Input type="text"  name={'title'} value={title} onChange={(event) => this.searchFeature(event)} className={'form-control'} placeholder={'Title'}/>
                        { searchResult &&  <div>
                            <ul className={'list-group list-group-search border-0'}>
                                <li className={'list-group-header p-3'}> <strong> Already requested {type} </strong></li>
                                {
                                    map(searchResult,function (key,search) {
                                        return(
                                            <li key={key} className={'list-group-item p-0'}  onClick={()=>setDialog({show:false})} ><NavLink tag={Link} to={`/${validUrl(current_product.title)}/featuredetail/${validUrl(search.title)}/?fid=${key}&pid=${current_product.id}`}> { getHighlightedText(search.title,title)}</NavLink> </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>}
                    </FormGroup>

                    <Row form>
                        {versions && <Col md={6}>
                            <FormGroup>
                                <Label>Version</Label>
                                <Input type="select" name="version" value={version}  onChange={(event) => this.setState({version:event.target.value})}>
                                    <option  value=''>Select</option>
                                    {
                                        map(versions, function (key, version) {
                                            return(<Fragment  key={key}>
                                                    <option  value={version.version}>{version.version}</option>
                                                    {
                                                        version.sub && map(version.sub, function (key, version) {
                                                            return(
                                                                <option key={key} value={version.version}> &nbsp;&nbsp; {version.version}</option>
                                                            )
                                                        })
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>}

                        {type === 'bug' &&    <Col md={6}>
                            <FormGroup>
                                <Label for="examplePassword">Priority</Label>
                                <Input type="select" name="priority" value={priority}  onChange={(event) => this.setState({priority:event.target.value})}>
                                    <option value="medium">Important</option>
                                    <option value="high">Critical</option>
                                </Input>
                            </FormGroup>
                        </Col>}
                    </Row>


                    {id &&
                    <Fragment>
                        <FormGroup>
                            <Label>Status</Label>
                            <Input type="select" name="status" value={status}  onChange={(event) => this.setState({status:event.target.value})}>
                                <option value="completed">Completed</option>
                                <option value="unconfirmed">Unconfirmed</option>
                                <option value="declined">Declined</option>
                                <option value="review">Review</option>
                            </Input>
                        </FormGroup>

                        <FormGroup check className={'mb-3'}>
                            <Label check><Input onChange={(event) => this.setState({hot:event.target.checked?1:0})} type="checkbox"   />  Hot</Label>
                        </FormGroup>
                    </Fragment>
                    }


                    <div className="comment-box mt-3">
                        <FormGroup>
                            <textarea  name={'content'} value={content} onChange={(event) => this.setState({content:event.target.value})} className={'form-control'} placeholder={'Detail'}/>
                        </FormGroup>

                        <div>
                            {Boolean(files.length) && <div className={'attachments'}>
                                {
                                    map(files,function (key,file) {
                                        return(
                                            <div key={key}> {file && <FileIcon  attachment={file}  index={key} removeAttachment={that.removeAttachment} /> } </div>
                                        )
                                    })
                                }
                            </div>}
                        </div>

                        <FileUploader handleFileUpload={this.handleFileUpload} />


                    </div>






                    {id ? <FormGroup className={'mt-3'}>
                        <Button color="primary" onClick={()=>this.onSubmit()}> {type === 'feature'?'Edit Idea':'Edit Bug Report'} </Button>
                    </FormGroup> : <FormGroup className={'mt-3'}>
                        <Button color="primary" onClick={()=>this.onSubmit()}> {type === 'feature'?'Add Idea':'Add Bug Report'} </Button>
                        </FormGroup>}

                </Form>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        versions: state.appApiData.version,
        products: state.appApiData.product,
        current_product: state.appApiData.current_product,
        isAuthenticated: state.authentication.isAuthenticated,
        user: state.authentication.authDetails.user,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
