import React, {Component} from "react";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {map} from "../../lib/functions";
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {adminUrl} from "../../lib/setting";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state={"id":'',"cid":'',"publish":1,"type":1,"createdby":'',"title":"","info":"","status":2,"keywords":"","metatag":"","views":1,"pid":props.current_product.id}
    }

    componentDidMount() {
        if(this.props.data){
            this.setState({...this.props.data});
        }
        if(this.props.user){
            this.setState({createdby:this.props.user.uid});
        }
    }

    onSubmit = () => {
        const {id} = this.state;
        const {setDialog} = this.props;
        requestApi({
            method: id?methods.put:methods.post,
            action: actions.article,
            body: {...this.state},
            otherurl:adminUrl
        }, (response) => {
            if (response.status === SUCCESS) {
                setDialog({
                    show: false,
                });
            }
        });
    };



    render() {

        const {title,info,type,metatag,keywords,status,cid,id,pid} = this.state;
        const {category,products} = this.props;

        return (
            <div>
                <Form onSubmit={this.handleSubmit} noValidate >

                    <FormGroup>
                        <Label for="exampleAddress">Products</Label>
                        <Input type="select" name="pid" value={pid}  onChange={(event) => this.setState({pid:event.target.value})}>
                            {
                                map(products, function (key, product) {
                                    return(
                                        <option key={key} value={product.id}>{product.title}</option>
                                    )
                                })
                            }
                        </Input>
                    </FormGroup>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleEmail">Title</Label>
                                <Input type="text" name={'title'} value={title} onChange={(event) => this.setState({title:event.target.value})} className={'form-control'} placeholder={'Title'}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="examplePassword">Listed In</Label>
                                <Input type="select" name="cid" value={cid}  onChange={(event) => this.setState({cid:event.target.value})}>
                                    <option value="">Select </option>
                                    {
                                        map(category, function (key, cat) {
                                            return(
                                                <option key={key} value={cat.id}>{cat.title}</option>
                                            )
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    {!id && <FormGroup>
                        <Label for="exampleAddress">Information</Label>
                        <textarea  name={'info'} value={info} onChange={(event) => this.setState({info:event.target.value})} className={'form-control'} placeholder={'Information'}/>
                    </FormGroup>}

                    <FormGroup>
                        <Label for="exampleAddress">Meta Tags</Label>
                        <textarea  name={'metatag'} value={metatag} onChange={(event) => this.setState({metatag:event.target.value})} className={'form-control'} placeholder={'Meta Tags'}/>
                    </FormGroup>

                    <FormGroup>
                        <Label for="exampleAddress">Site Keywords </Label>
                        <textarea  name={'keywords'} value={keywords} onChange={(event) => this.setState({keywords:event.target.value})} className={'form-control'} placeholder={'Site Keywords'}/>
                    </FormGroup>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Status</Label>
                                <Input type="select" name="status" value={status}  onChange={(event) => this.setState({status:event.target.value})}>
                                    <option value="1">Publish</option>
                                    <option value="0">Unpublish</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Type</Label>
                                <Input type="select" name="type" value={type}  onChange={(event) => this.setState({type:event.target.value})}>
                                    <option value="1">Public</option>
                                    <option value="0">Private</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className={'mt-3'}>
                        <Button color="primary" onClick={()=>this.onSubmit()}>Save</Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        category: state.appApiData.category,
        products: state.appApiData.product,
        current_product: state.appApiData.current_product,
        user: state.authentication.authDetails.user,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
