
import {render, store} from "../index";
import {login, logout} from "./Store/actions/authentication";
import requestApi, {actions, methods, SUCCESS} from "./ServerRequest";
import AddEditFeatureBug from "../pages/AddEditFeatures";
import Login from "../pages/Login";
import React from "react";
import {setDialog} from "./Store/actions/components";
import {setInit, setProduct} from "./Store/actions/appApiData";




export const checkUserAccess = (data) => {
    if (data) {
        const token = JSON.parse(data).token;
        const user = JSON.parse(data).data;
        /*const {staff, Userid, Uemail, UserName,version} = user;*/
        setAuth(data);
        store.dispatch(login({token,user}));
        render();
    } else {
        removeAuth();
        store.dispatch(logout());
        render();
    }
};


export const setAuth = (data) => localStorage.setItem('kb-auth', data);
export const getAuth = () => localStorage.getItem('kb-auth');
export const removeAuth = () => localStorage.removeItem('kb-auth');



export const checkLogin = () => {
    const {isAuthenticated}  = store.getState().authentication;
    if(!isAuthenticated){
        popupLogin();
        return false;
    }
    return true;
}


export const addEditBugFeature = (feature) => {

   let title = '';
   if(feature.type === 'feature'){
       title = 'Post Your Idea';
       if(feature.id){
           title = 'Edit Your Idea';
       }
   }
   else{
       title = 'Post Your Bug Report';
       if(feature.id){
           title = 'Edit Your Bug Report';
       }
   }

    if(checkLogin()){
        store.dispatch(setDialog({
            show: true,
            title:  title,
            maxWidth: "lg",
            fullScreen: true,
            className:'modal-postidea',
            component: () => <AddEditFeatureBug data={feature} />
        }));
    }
}

export const getInit = () => {
    const pid = searchParamsToObj('pid');
    requestApi({
        method: methods.get,
        action: actions.init,
        queryString:{pid:pid?pid:1},
        alert:false,
    }, (response) => {
        if(response.data) {
            store.dispatch(setInit(response.data));
            const current_product = response.data.product.filter(function (item) {
                return item.selected === true
            });
            store.dispatch(setProduct(current_product[0]));
            store.dispatch(setDialog({show: false,}));
            checkUserAccess(getAuth());
        }
    });
};



function gtag(){   window.dataLayer.push(arguments);}
window.dataLayer = window.dataLayer || [];
gtag('js', new Date());
gtag('config', 'UA-25117220-1');

export const analyticIntercom = () =>{
    let path = window.location?.pathname;
    let page = path.split("/").pop();
    gtag('config', 'UA-1234567-89', {'page_path': `/${page}`});
    //window.Intercom('update');
    //Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)})
    window.scrollTo(0, 0);
}


export const scrollTo = (hash) => {
    const scrollToAnchor = () => {
        const hashParts = hash.split('#');
        if (hashParts.length > 2) {
            const hash = hashParts.slice(-1)[0];
            document.querySelector(`#${hash}`).scrollIntoView();
        }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
}

export const  scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

export const popupLogin = () => {
    store.dispatch(setDialog({
        show: true,
        title: 'Login',
        maxWidth: "md",
        fullScreen: true,
        className:'modal-login',
        component: () => <Login />
    }));
}

export const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts.map((part, i) =>
        <span key={i} className={part.toLowerCase() === highlight.toLowerCase() ?'highlight':''}>
            { part }
        </span>)
    } </span>;
}

export const changeProduct = (product) => {
    store.dispatch(setProduct(product));
}

export const  uploadFile = (file,callback) =>{
    if(file) {
        requestApi({
            method: methods.get,
            action: 'getuploadurl',
            queryString: {file_name: file.name, file_type: file.type},
            loader:false,
            otherurl: 'https://apigateway.dhru.com/v1/',
            xhrRequest: true,
        }, (responseUrl) => {
            if (responseUrl.status === SUCCESS) {
                let requestOptions = {
                    method: 'PUT',
                    headers: {"Content-Type": file.type},
                    body: file,
                };
                fetch(responseUrl.upload_url, requestOptions)
                    .then(response => {
                        if (response.status === 200) {
                            callback({
                                download_url: responseUrl.download_url,
                                file_name: responseUrl.original_file_name,
                            })
                        }
                    })
                    .catch(error => {
                        //console.log('error', error)
                    });
            }
        })
    }
}

export const searchParamsToObj = (searchparams) => {

    const queryParameters = new URLSearchParams(window.location.search)
    return  queryParameters.get(searchparams)

    /*if(!searchparams){
        return false;
    }
    let search = searchparams.substring(1);
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');*/
}

export const validUrl = (string) => {
    if(!string){
        return false;
    }
    return string.replace(/[^a-zA-Z0-9]/g,'-');
}

export const objToArray = (data, key) => {
    if (data) {
        let result = [];
        for (let i in data) {
            if (i && data[i]) {
                if (!key) {
                    data[i]['__key'] = i;
                }
                result.push(data[i]);
            }
        }
        return result;
    }
};

export const getNestedChildren = (arr, find, mid, id) => {
    var out = [];
    for (let i in arr) {
        if (arr[i][mid] === find) {
            let sub = getNestedChildren(arr, arr[i][id], mid, id);
            arr[i].sub = sub;
            out.push(arr[i]);
        }
    }
    return out
};

export const nestedGroupList = (arr, find, mid, id, module) => {
    if (arr) {
        return Object.values(arr).filter((pg) => {
            return pg[mid] === find;
        }).map((pg => {
            return {...pg, module: pg[module], children: nestedGroupList(arr, pg[id], mid, id, module)}
        }));
    } else {
        return []
    }
};

export const nestedToList = (arr, id, child, mid, pid) => {
    let list = {};

    function getChild(arr, id, child, mid, pid) {
        for (let i = 0; i < arr.length; i++) {
            arr[i][mid] = pid;
            arr[i]['level'] = 0;
            list[arr[i][id]] = (arr[i]);
            if (arr[i][child]) {
                getChild(arr[i][child], id, child, mid, arr[i][id]);
            }
        }
    }

    getChild(arr[child], id, child, mid, pid, 0);
    return list;
};



export const clone = (obj) => {
    var copy;
    if (null == obj || "object" != typeof obj) return obj;
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = clone(obj[i]);
        }
        return copy;
    }
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const map = (object, callback) => {
    return Object.keys(object).map(function (key) {
        return callback(key, object[key]);
    });
};

export const findObject = (array, field, value) => {
    let find = array.filter(function (item) {
        return item[field] === value
    });
    return find[0];
};


export const filterObject = (array, field, value) => {
    let find = array.filter(function (item) {
        return item[field] === value
    });
    return find;
};

export function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export const configColumns = (array) => {
    let configColumn = {};
    array.forEach((v) => {
        if (v) {
            configColumn = {
                ...configColumn,
                [v]: v
            }
        }
    });
    return configColumn;
};












