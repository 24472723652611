import React, {Component,Fragment} from "react";
import {Link} from 'react-router-dom';
import {
    NavLink,
    NavItem,
   } from 'reactstrap';
import {connect} from "react-redux";
import {setDialog} from "../../lib/Store/actions/components";
import {validUrl} from "../../lib/functions";



export class Links extends Component {


    hideMenu = () => {
        const {setDialog} = this.props;
        setDialog({
            show: false,
        });
    };


    render() {

        const {current_product,version} = this.props;

        return (
            <Fragment>

                {current_product && <Fragment>
                    <NavItem onClick={this.hideMenu}>
                        <NavLink tag={Link}  to={`/${validUrl(current_product.title)}/article/${validUrl(current_product.article.title)}/?aid=${current_product.article.id}&cid=${current_product.article.cid}&pid=${current_product.id}`}>   Knowledgebase  </NavLink>
                    </NavItem>
                    <NavItem onClick={this.hideMenu}>
                        <NavLink  tag={Link}  to={`/${validUrl(current_product.title)}/features/feature/unconfirmed?pid=${current_product.id}`}>Feature Request</NavLink>
                    </NavItem>
                    <NavItem onClick={this.hideMenu}>
                        <NavLink tag={Link} to={`/${validUrl(current_product.title)}/features/bug/unconfirmed?pid=${current_product.id}`}>Bug Report</NavLink>
                    </NavItem>
                    {version &&  <NavItem onClick={this.hideMenu}>
                        <NavLink tag={Link} to={`/${validUrl(current_product.title)}/releasenotes/${validUrl(version[0].version)}/?vid=${version[0].id}&pid=${current_product.id}`}>Release Notes</NavLink>
                    </NavItem>}

                </Fragment>}

            </Fragment>
        );
    }

}



const mapStateToProps = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    version : state.appApiData.version
});

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Links);
