import React, {Component} from "react";
import {connect} from "react-redux";
import {checkLogin, validUrl} from "../../lib/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setDialog} from "../../lib/Store/actions/components";
import {
    Badge,
    NavLink,
} from "reactstrap";
import {Link} from "react-router-dom";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import ReactTimeAgo from 'react-time-ago';
import {faCalendar, faCodeMerge, faComments, faThumbsUp} from "@fortawesome/pro-light-svg-icons";



class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpened: [],
            voteid:''
        };
    }

    componentDidMount() {

    }

    submitVote(fid,type,voteid){
        if(checkLogin()){
            const {user} = this.props;
            let vote = {fid: fid, uid: user.uid, type: type, voteid: voteid}
            requestApi({
                method: methods.put,
                action: actions.vote,
                body: vote
            }, (response) => {
                if (response.status === SUCCESS) {

                }
            });
        }
    }



    render() {
        const {feature,status,current_product} = this.props;
        return (
            <div className={''}>
                <div  className={`card py-5 feature-list  ${status}`}>
                    <div className={'d-flex align-items-center'}>
                        <div className={'w-100'}>
                            <NavLink tag={Link} to={`/${validUrl(current_product.title)}/featuredetail/${validUrl(feature.title)}/?fid=${feature.id}&pid=${current_product.id}`}>
                                <h2 className={'card-title'}>
                                    {feature.title}
                                </h2>
                                <p className={'text-muted m-0'}>
                                    {feature.content && atob(feature.content)}
                                </p>
                                <div className={'d-flex text-muted align-items-center mt-3 w-100 flex-wrap'}>
                                    {feature.priority  && <Badge className={`badge mr-4 tags text-uppercase  badge-${feature.priority}`}> {feature.priority} </Badge>}

                                    <small className={'mr-4  tags'}>
                                        <FontAwesomeIcon icon={faCalendar} /> <span className={'text-dark'}> <ReactTimeAgo date={new Date(feature.date)}  />  </span>
                                    </small>

                                    {feature.version && <small className={'mr-4  tags'}>
                                        <FontAwesomeIcon icon={faCodeMerge} /> <span className={'text-dark'}> {feature.version} </span>
                                    </small>}

                                    {feature.completedversion && <small className={'mr-4  tags'}>
                                        <FontAwesomeIcon icon={faCodeMerge} color={'green'} /> <span className={'text-dark'}> {feature.completedversion} </span> Completed
                                    </small>}



                                    <small className={'mr-4  tags'}>
                                        <FontAwesomeIcon icon={faComments} /> <span className={'text-dark'}> {feature.totalcomments} </span> Comments
                                    </small>
                                    <small className={'mr-auto  tags'}>
                                        <FontAwesomeIcon icon={faThumbsUp} /> <span className={'text-dark'}> {feature.vote} </span> Votes
                                    </small>


                                    <small className={'tags text-right'}>
                                       ~ {feature.user.firstname} {feature.user.lastname}
                                    </small>

                                </div>
                            </NavLink>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        user: state.authentication.authDetails.user,
        current_product : state.appApiData.current_product
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
