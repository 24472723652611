import React, {Component } from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "reactstrap";
import {faFileArchive, faFilePdf} from "@fortawesome/pro-light-svg-icons";

class Index extends Component {

    render() {
        const {attachment,index,removeAttachment} = this.props;
        let extentation = attachment.file_name?attachment.file_name.split('.').pop():'';
        return (
            <div   className={'attachment-thumb'}>
                {attachment.download_url ?
                    <div>
                        {extentation === 'pdf' && <FontAwesomeIcon   icon={faFilePdf} size={'2x'} /> }
                        {extentation === 'zip' && <FontAwesomeIcon   icon={faFileArchive} size={'2x'} /> }
                        {(extentation === 'jpg' || extentation === 'jpeg' || extentation === 'png') && <img src={attachment.download_url} alt=""/> }
                    </div> :
                    <Spinner />
                }
                {removeAttachment && <span className={'attachment-remove'} onClick={()=>removeAttachment(index)}> X </span>}
            </div>
        )
    }
}

export default Index
