import {
    SET_INIT,
    SET_PRODUCT,
    SET_FEATURES,
    SET_BUGS,
    SET_RELEASES,
} from "../definitions/appApiData";


const setInit = (data) => ({
    type: SET_INIT,
    data
});
const setProduct = (product) => ({
    type: SET_PRODUCT,
    product
});
const setFeatures = (features) => ({
    type: SET_FEATURES,
    features
});
const setBugs = (bugs) => ({
    type: SET_BUGS,
    bugs
});
const setReleases = (release) => ({
    type: SET_RELEASES,
    release
});

export {
    setInit,
    setProduct,
    setFeatures,
    setBugs,
    setReleases
}
