import React, {Component, useEffect, useState} from "react";
import {connect} from "react-redux";
import {addEditBugFeature, analyticIntercom, map, validUrl} from "../../lib/functions";
import {setDialog} from "../../lib/Store/actions/components";
import {Nav, NavItem, NavLink, Badge, Input, FormGroup} from 'reactstrap';
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {setFeatures} from "../../lib/Store/actions/appApiData";
import FeatureRow from "./FeatureRow";
import {bugs_status, features_status} from "../../lib/static";
import {Link, useParams} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/pro-light-svg-icons";



const Index = (props) => {


    const params = useParams()


    const [features,setFeatures] = useState(false);
    const [type,setType] = useState(params?.type)
    const [status,setStatus] = useState(params?.status)
    const [offset,setOffset] = useState(0)
    const [sortby,setSortby] = useState('newidea')
    const [pageCount,setPagecount] = useState(10)


   /* shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(nextState, this.state);
    }*/
    const {countfeature,countbug,current_product} = props;

    const getFeatures = () => {


        requestApi({
            method: methods.get,
            action: actions.features,
            alert:false,
            queryString:{type:params.type,status:params.status,start:offset,sortby:sortby,pid:current_product.id}
        }, (response) => {
            if(response.status === SUCCESS){
                setFeatures(response.data);
                setPagecount(Math.ceil(response.info.total / 10));
                //setFeatures(response.data);
            }
        })
    }


    const handlePageClick =async (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 10);
        await setOffset(offset);
        await getFeatures();
    };


    useEffect(()=>{
        setStatus(params.status);
        document.title = current_product.title + ' - Bug Report';
        getFeatures();
    },[params.status])

    useEffect(()=>{
        setType(params.type)
        document.title = current_product.title + ' - Feature Request';
        getFeatures();
    },[params.type])

    useEffect(()=>{
        analyticIntercom();
    },[])


    return (
        <div className={'page-container pb-5'}>

            <div className={'container'}>
                <div className={'d-flex center'}>
                    <h1 className="title"> {type === 'feature'?'Feature Request':'Bug Report'} </h1>
                    <input type="button" value={type ==='feature'?'Post Idea':'Post Bug'} className={`btn btn-${type ==='feature'?'outline-success':'outline-danger'}`} onClick={()=>{addEditBugFeature({id:'',type:type})}} />
                </div>
                <div>
                    <Nav tabs>
                        {
                            map(type === 'feature'?features_status:bugs_status, function (key, feature) {
                                return(
                                    <NavItem key={key}>
                                        <NavLink className={feature.value === status ? 'active':''}  tag={Link} to={`/${validUrl(current_product.title)}/features/${type}/${feature.value}/?pid=${current_product.id}`}>
                                            <div>

                                                        <span className={'mr-2 text-capitalize'}> {feature.label}  {Boolean(countfeature) && <Badge> {feature.value === 'requested' && <FontAwesomeIcon icon={faClock}   />}
                                                            {type === 'feature'?countfeature[feature.value]:countbug[feature.value]}</Badge> } </span>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                )
                            })
                        }

                        <FormGroup className={'ml-auto d-none d-md-block'}>
                            <Input type="select" className={'sortby'} name="sortby" value={sortby}  onChange={async (event) => {
                                await setSortby(event.target.value)
                                getFeatures()
                            }}>
                                <option value="newidea">New Ideas</option>
                                <option value="topidea">Top Ideas</option>
                                <option value="hotidea">Hot Ideas</option>
                            </Input>
                        </FormGroup>

                    </Nav>
                    {features && <div className={''}>
                        {
                            features &&  map(features, function (key, feature) {
                                return(
                                    <FeatureRow key={key} feature={feature} status={status} />
                                )
                            })
                        }
                    </div>}
                </div>
            </div>


            <div className={'pagging-container'}>
                <div className={'container'}>
                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        features: state.appApiData.features,
        countfeature: state.appApiData.countfeature,
        countbug: state.appApiData.countbug,
        isAuthenticated: state.authentication.isAuthenticated,
        current_product: state.appApiData.current_product,
        user: state.authentication.authDetails.user,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setFeatures: (category) => dispatch(setFeatures(category)),
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);



/*
{feature.value === 'declined' && <FontAwesomeIcon icon={["fal", "ban"]}    />}
{feature.value === 'completed' && <FontAwesomeIcon icon={["fal", "check"]}     />}
{feature.value === 'unconfirmed' && <FontAwesomeIcon icon={["fal", "circle"]}      />}
{feature.value === 'notabug' && <FontAwesomeIcon icon={["fal", "not-equal"]}   />}
{feature.value === 'review' && <FontAwesomeIcon icon={["fal", "comments"]}     />}*/
