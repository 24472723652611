export const actions = Object.freeze({
    login: 'login',
    category: 'category',
    article: 'article',
    features:'features',
    init:'init',
    version:'version',
    release:'release',
    comments:'comments',
    vote:'vote',
    search:'search',
    orders:'orders'
});
