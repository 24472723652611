import React, {Component,Fragment} from "react";
import {connect} from "react-redux";
import {Route,Routes} from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import FeaturesList from "../pages/FeaturesList";
import FeatureDetail from "../pages/FeatureDetail";
import ReleaseNotes from "../pages/ReleaseNotes";
import ManageVersion from "../pages/ManageVersion";

class RouterComponent extends Component {



    render() {


        return (<Fragment>

                <Routes >

                    <Route path="/" exact={true} element={<Dashboard  />}/>
                    <Route path="/:productTitle" exact={true} element={<Dashboard  />}/>
                    <Route path="/:productTitle/article/:articleTitle"  exact={true} element={<Dashboard  />}/>

                    <Route path="/:productTitle/features/:type/:status" exact={true} element={<FeaturesList />}/>
                    <Route path="/:productTitle/featuredetail/:featureTitle" exact={true}  element={<FeatureDetail  />}/>
                    <Route path="/:productTitle/manageversion" exact={true}   element={<ManageVersion  />}/>
                    <Route path="/:productTitle/releasenotes" exact={true}   element={<ReleaseNotes  />}/>
                    <Route path="/:productTitle/releasenotes/:version" exact={true}   element={<ReleaseNotes  />}/>

                </Routes>

        </Fragment>)
    }
}
const mapStateToProps = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
});

export default connect(mapStateToProps)(RouterComponent);


//getInit();
