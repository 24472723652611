import React, {Component, useRef, useState} from 'react';
import requestApi, {actions,methods,SUCCESS} from "../../lib/ServerRequest";
import {checkUserAccess} from "../../lib/functions";
import {Col, Row, Button, Form, FormGroup, Input, InputGroup, InputGroupText, InputGroupAddon} from 'reactstrap';
import {setDialog} from "../../lib/Store/actions/components";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {faLock, faUser} from "@fortawesome/pro-light-svg-icons";
import {v4 as uuid} from "uuid";
import AuthCode from "react-auth-code-input";
import Countdown from "react-countdown";

const Index = (props) => {


    const otpverifyRef = useRef()
    const mobileRef = useRef()
    const counterRef = useRef()
    const AuthInputRef = useRef();
    let otp ='';

    const [counter, setCounter] = useState(false)

    let [initdata,setInitdata] = useState({email:'',password:'','g-recaptcha-response':"g-recaptcha-response-gjgjh-kjkljkl-mjbkjhkj-bbkj",deviceid: 'asdfadsf' })

    const onSubmit = async () =>{

        const {setDialog,googleReCaptchaProps} = props;

        requestApi({
            method: methods.post,
            action: actions.login,
            body: {...initdata,otp:otp}
        }, (response) => {

            if (response.status === SUCCESS) {
                setDialog({
                    show: false,
                });
                checkUserAccess(JSON.stringify(response));
            }
            else{
                AuthInputRef.current?.clear();
                AuthInputRef.current?.focus();
                otpverifyRef.current.style.display = 'block';
                mobileRef.current.style.display = 'none';
                setCounter(true)
            }
        });

    };

    const openWindow = () =>{
        window.open('https://account.dhru.com/','_blank');
    }

    const handleOnChange = async (value) => {
        if (value.length === 6) {
            otp = value;
            //await setInitdata({...initdata,otp})
        }
    };

    const validate = (values)=>{
        const error = {};
        return error;
    };

    const renderer = ({hours, minutes, seconds, completed}) => {
        return <div className={'d-flex justify-content-between align-items-center'}>
            <div>
                <div style={{color: '#3174de'}} className={!completed ? 'text-muted' : ''} onClick={async () => {
                    if(completed){
                        setCounter(false)
                        otp = '';
                        onSubmit();
                    }
                }}>
                    Resend OTP
                </div>
            </div>
            {!completed && <small className={' text-muted'}>Waiting {seconds} Sec</small>}
        </div>
    };

    const {email,password} = initdata;
    return (
        <div>
            <Form onSubmit={onSubmit} noValidate >
                {<div ref={mobileRef} style={{display:'block'}}>
                <div className={'mb-5 text-muted'}>
                    <small> You must login to access this page. These login details differ from your websites control panel username and password.</small>
                </div>

                <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><FontAwesomeIcon icon={faUser} /></InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name={'email'} value={email} onChange={(event) => setInitdata({...initdata,email:event.target.value})} className={'form-control'} placeholder={'Your dhru account username'}/>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><FontAwesomeIcon icon={faLock} /></InputGroupText>
                                </InputGroupAddon>
                                <Input type="password" name={'password'} value={password} onChange={(event) => setInitdata({...initdata,password:event.target.value})} className={'form-control'} placeholder={'Your dhru account password'}/>
                            </InputGroup>
                            <div className="text-right">
                                <span  onClick={openWindow} className={'text-secondary cursor-pointer'}  > <small> Forgot Password </small></span>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup className={'mt-3'}>
                    <Button color="primary w-100 p-2"   onClick={()=>onSubmit()}>Login</Button>
                </FormGroup>

                </div>}


                {<div>

                    <div   ref={otpverifyRef} style={{display: 'none'}}>


                        <div>
                            <div className={'mb-3'}>

                                <div className={'mb-3'}> OTP was sent to your registered email    </div>

                                <div className={'input-otp'}>
                                    <AuthCode allowedCharacters='numeric' ref={AuthInputRef}
                                              onChange={handleOnChange}/>
                                </div>
                            </div>

                            {counter && <Countdown ref={counterRef} date={Date.now() + 30000} key={uuid}
                                       renderer={renderer}/>}

                            <FormGroup className={'mt-3'}>
                                <Button color="primary w-100 p-2"   onClick={()=>onSubmit()}>Verify OTP</Button>
                            </FormGroup>

                        </div>

                    </div>




                </div>}

            </Form>


        </div>
    )

}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Index));



