import React, {Component} from "react";
import {connect} from "react-redux";
import {Badge, FormGroup} from "reactstrap";
import {setDialog} from "../../lib/Store/actions/components";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments,faThumbsUp} from  '@fortawesome/pro-light-svg-icons';
import {faThumbsUp as fasThumbsUp} from  '@fortawesome/free-solid-svg-icons';
import {addEditBugFeature, analyticIntercom, checkLogin, map, searchParamsToObj, uploadFile} from "../../lib/functions";
import FileUploader from "../../components/FileUploader";
import ProfilePicture from "../../components/ProfilePicture";
import FileIcon from "../../components/FileIcon";
import ReactTimeAgo from 'react-time-ago'


class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: false,
            title: "",
            content:"",
            type:"",
            version:"",
            vote:"",
            uid:"",
            attachment:[],
            status:"",
            date:"",
            hot:"",
            priority:"",
            delete:"",
            completedversion:"",
            lowpri:"",
            comments:'',
            addcomment:'',
            voteid:'',
            commentattachment:[],
            files:[]
        }
    }

    getFeaturedetail(){
        const fid = searchParamsToObj('fid');
        const {user} = this.props;
        requestApi({
            method: methods.get,
            action: actions.features,
            alert:false,
            queryString:{featureid:fid,uid:user.uid?user.uid:''}
        }, (response) => {
            if(response.status === SUCCESS){
                this.setState({...response.data});
            }
        })
    }

    componentDidMount() {
        analyticIntercom();
        this.getFeaturedetail();
    }

    submitComment(){
        checkLogin()
        {
            const {id, addcomment, type, commentattachment} = this.state;
            const {user} = this.props;
            let comment = {fid: id, uid: user.uid, comment: addcomment.comment, type: type, files: commentattachment}
            requestApi({
                method: methods.post,
                action: actions.comments,
                body: {...comment}
            }, (response) => {
                if (response.status === SUCCESS) {
                    this.setState({addcomment:{comment:''},commentattachment:[]},()=>{
                        this.getFeaturedetail();
                    })
                }
            });
        }
    }


    submitVote(){
        const {id,type,voteid} = this.state;
        const {user} = this.props;

        let votepost = {fid:id,uid:user.uid,type:type}
        if(voteid) {
            votepost = {fid: id, uid: user.uid, type: type, voteid: voteid}
        }
        requestApi({
            method: methods.put,
            action: actions.vote,
            body: votepost
        }, (response) => {
            if (response.status === SUCCESS) {
                let voteid = response.data.id;
                let user = [];
                if(response.data.user){
                    map(response.data.user,function (key,value) {
                        user.push(value.user);
                    });
                }
                this.setState({...this.state,voteid:voteid,vote:user},()=>{

                });
            }
        });
    }


    handleFileUpload = (event) => {
        const that = this;
        const file = event.target.files[0];
        if(file.size < 186000) {
            this.setState({
                commentattachment: [...this.state.commentattachment, {download_url: '', file_name: ''}]
            });
            uploadFile(file, function (response) {
                let last = that.state.commentattachment.length - 1;
                let array = [...that.state.commentattachment];
                array[last] = response;
                that.setState({
                    commentattachment: array
                })
            });
        }
        else{
            const {setDialog} = this.props;
            setDialog({
                show: true,
                title: '',
                maxWidth: "lg",
                fullScreen: true,
                className: 'modal-image',
                component: () =>  <div className={'p-5'}><h6>Can't upload more than 10MB</h6></div>
            });
        }
    }

    showImage = (attachment) =>{
        const url = attachment.download_url;
        let extentation = attachment.file_name.split('.').pop();
        if(extentation === 'jpg' || extentation === 'jpeg' || extentation === 'png' || extentation === 'gif') {
            const {setDialog} = this.props;
            setDialog({
                show: true,
                title: '',
                maxWidth: "lg",
                fullScreen: true,
                className: 'modal-image',
                component: () => <img src={url} alt={''}/>
            });
        }
        else{
            window.open(url,'_blank');
        }
    }


    removeAttachment = (index) =>{
        let array = [...this.state.commentattachment]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({commentattachment: array});
        }
    }

    render() {
        const {id,title,content,type,vote,status,date,priority,comments,addcomment,voteid,commentattachment,files} = this.state;
        const {isAuthenticated,user} = this.props;
        const that = this;
        return (
            <div>

                {id &&  <div className={'page-container'}>
                    <div className={'container'}>
                        <div className="row">

                            <div className="col-lg-12">
                                <h2 className="title p-3">
                                    {title}
                                </h2>
                            </div>


                            <div className="col-lg-8 mb-3">
                                <div className="">



                                    <div className="content p-3">
                                       {content && atob(content)}


                                        {files && Boolean(files.length) && <div className={'comment-list'}>  <div className={'comment-attachments grid'}>
                                            {
                                                map(files,function (key,file) {
                                                    return(
                                                        <div onClick={()=>that.showImage(file)}   key={key} className={'comment-image box'}>
                                                            <FileIcon  attachment={file}   index={key}  />
                                                            <small> {file.file_name}</small>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div></div>}


                                    <div className="my-3 mt-5">
                                        <h5><FontAwesomeIcon icon={faComments} /> Comments({Object.keys(comments).length}) </h5>

                                       <div className="comment-box">
                                            <form>
                                                <div>
                                                    <FormGroup>
                                                        <textarea  name={'comment'} value={addcomment.comment} onChange={(event) => this.setState({addcomment:{comment:event.target.value}})} className={'form-control'} placeholder={'Write your comment here...'}/>
                                                    </FormGroup>

                                                    {commentattachment && Boolean(commentattachment.length) && <div className={'attachments'}>
                                                        {
                                                            map(commentattachment,function (key,attachment) {
                                                                return(
                                                                    <FileIcon  attachment={attachment} key={key} index={key} removeAttachment={that.removeAttachment} />
                                                                )
                                                            })
                                                        }

                                                    </div>}



                                                    <div className="d-flex justify-content-between">
                                                        <FileUploader handleFileUpload={this.handleFileUpload} />
                                                        <button type="button" className="btn btn-primary" onClick={()=>this.submitComment()}>Post Comment </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>



                                        {comments &&  <div className={'comment-list mt-5'}>
                                        { Boolean(Object.keys(comments).length) &&
                                            <div>
                                                {
                                                    map(comments, function (key, comment) {
                                                       return(
                                                           <div className="my-5" key={key}>
                                                               <div className="d-flex">
                                                                   <div className="mr-3"> <ProfilePicture dp={`${comment.user.firstname?comment.user.firstname.charAt(0):''}${comment.user.lastname?comment.user.lastname.charAt(0):''}` }  />    </div>
                                                                   <div className="w-100">
                                                                       <h6  className={'center'}> <div> <span className={'text-muted'}>{comment.user.firstname} {comment.user.lastname}</span>  <small className={'text-light'}>    commented   </small> </div> <small className={''}> {comment.date &&  <ReactTimeAgo  date={new Date(comment.date)}   />}  </small></h6>
                                                                       <div>
                                                                           <small>{comment.comment}</small>
                                                                       </div>
                                                                        {comment.files && Boolean(comment.files.length) && <div className={'comment-attachments grid'}>
                                                                           {
                                                                               map(comment.files,function (key,attachment) {
                                                                                   return(
                                                                                       <div onClick={()=>that.showImage(attachment)}   key={key} className={'comment-image box'}>
                                                                                           <FileIcon  attachment={attachment}  index={key}  />
                                                                                            <small> {attachment.file_name}</small>
                                                                                       </div>
                                                                                   )
                                                                               })
                                                                           }
                                                                       </div>}
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       )
                                                    })
                                                }
                                            </div>}
                                        </div>}
                                    </div>




                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 ">
                                <div className={'mt-4 sidebar'}>


                                    <div className="border mt-3 p-3 p-lg-4 rounded">
                                        <table className={'w-100'}><tbody>
                                            {type === 'bug' && <tr>
                                                <td> <small> Priority </small> </td>
                                                <td>  <Badge className={`badge mt-2 text-uppercase  badge-${priority}`}> {priority} </Badge></td>
                                            </tr>}
                                            <tr>
                                                <td> <small> Posted Date </small> </td><td>  {date} </td>
                                            </tr>
                                            <tr>
                                                <td> <small>Status </small>  </td><td>  <Badge className={`badge mt-2 text-uppercase  badge-${status}`}> {status} </Badge> </td>
                                            </tr>
                                            <tr>
                                                <td> <small> Requested by </small> </td><td>  {this.state.user.firstname} {this.state.user.lastname} </td>
                                            </tr>
                                        </tbody></table>
                                    </div>


                                    <div className={'mt-3 border p-3 text-center p-lg-4 rounded'}>

                                        {isAuthenticated && <button className={'bg-transparent border-0'}  onClick={()=>this.submitVote()}>
                                            <FontAwesomeIcon   color={voteid?'#1d2c4b':'#1e2d4c87'}  size={'2x'} icon={voteid?fasThumbsUp:faThumbsUp}  />
                                        </button>}

                                        <div>
                                            <div>Voter ({vote.length})</div>
                                            {isAuthenticated && user.staff && <div>
                                                {
                                                    map(vote, function (key, vote) {
                                                        return (<div  key={key}> <small className={'text-light'}>{vote.firstname} {vote.lastname}</small> </div>)
                                                    })
                                                }
                                            </div>}
                                        </div>

                                    </div>


                                    <div className="py-3 px-2">
                                        <div className={'center'}>
                                            <input type="button" size={'sm'} value={`Post ${type}`} className={`btn mr-3 btn-${type==='feature'?'outline-success':'outline-danger'}`} onClick={()=>{addEditBugFeature({id:'',type:type})}} />
                                            {isAuthenticated && user && user.staff &&  <input type="button" size={'sm'} value="Edit" className="btn btn-outline-secondary" onClick={()=> {addEditBugFeature({...this.state})}}    />}
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        authentication:state.authentication,
        user: state.authentication.authDetails.user,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
