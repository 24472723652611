import React, {Component, useEffect, useState} from "react";
import requestApi, {actions, methods, SUCCESS} from "../../lib/ServerRequest";
import {setReleases} from "../../lib/Store/actions/appApiData";
import {connect} from "react-redux";
import {Badge, Button, DropdownMenu, DropdownToggle, NavLink, UncontrolledDropdown} from "reactstrap";
import {setDialog} from "../../lib/Store/actions/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {map, searchParamsToObj, validUrl, scrollWidthOffset, analyticIntercom} from "../../lib/functions";
import {adminUrl} from "../../lib/setting";
import MarkdownIt from "markdown-it";
//import markdownItTocAndAnchor from "markdown-it-toc-and-anchor";
import LinkManageVersion from "../Navigation/manageversion";
import {Link, useParams} from "react-router-dom";
import MdEditor from "react-markdown-editor-lite";
import { HashLink as Scroll } from 'react-router-hash-link';
import {faBug, faLightbulbOn, faPencil} from "@fortawesome/pro-light-svg-icons";

const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
});



const VersionList = (props) => {

        const {versions,current_version_status,current_version,current_product} = props;

        return(
            <UncontrolledDropdown size={'sm'} className={'mt-2 w-100'}>
                <DropdownToggle caret  className={'w-100 center'}  color="secondary"> <span>Version : {current_version} </span>  <span className={'text-right'}>   <Badge className={`badge  text-uppercase  badge-${current_version_status}`}> {current_version_status} </Badge> </span> </DropdownToggle>
                {versions && <DropdownMenu style={{width:'100%'}}>
                    {
                        map(versions, function (key, version) {
                            return (<NavLink className={'center text-dark'} key={key} tag={Link} to={`/${validUrl(current_product.title)}/releasenotes/${validUrl(version.version)}/?vid=${version.id}&pid=${current_product.id}`}>
                                <span> {version.version} </span>
                                <Badge className={`badge mt-2 text-uppercase  badge-${version.status}`}> {version.status} </Badge>
                            </NavLink>)
                        })
                    }
                </DropdownMenu>}
            </UncontrolledDropdown>
        );

}

const Index = (props) => {

    const params = useParams()


    const [updatemode,setUpdatemode] = useState(false)
    const [version,setversion] = useState(params.version);
    const [sub,setsub] = useState('')
    const [status,setStatus] = useState('')
    const [featurelist,setfeaturelist] = useState('')
    const [summary,setsummary] = useState('')
    const [configuration,setconfiguration] = useState('')
    const [id,setid] = useState('')

    const editor = React.createRef()


    const vid = searchParamsToObj('vid');


     useEffect(()=>{

         const {current_product} = props;

         requestApi({
             method: methods.get,
             action: actions.release,
             alert:false,
             queryString:{pid:current_product.id,releaseid:vid?vid:''},
             otherurl:adminUrl
         }, (response) => {
             if(response.status === SUCCESS){

                 document.title = current_product.title + ' - Release Notes ' + response.data[0].version;
                 let array = response.data[0];
                 map(array,function (key,value) {
                     if(key === 'featurelist'){
                         array[key] = atob(value);
                     }
                     if(key === 'sub'){
                         map(array['sub'],function (key1,value) {
                             map(value,function (key2,value) {
                                 if(key2 === 'featurelist'){
                                     array['sub'][key1]['featurelist'] = atob(value);
                                 }
                             })
                         })
                     }
                 });


                 setsub(array['sub']);
                 setfeaturelist(array['featurelist']);
                 setversion(array['version']);
                 setStatus(array['status']);
                 setconfiguration(array['configuration'])
                 setid(array['id'])

             }
         })
     },[vid,version])

    useEffect(()=>{
        analyticIntercom();
    },[])



    const onSubmit = (id,summary,featurelist,configuration) => {
        let version = {id:id,summary:summary,featurelist:featurelist,configuration:configuration}
        requestApi({
            method: methods.put,
            action: actions.version,
            body: {...version},
            otherurl:adminUrl
        }, (response) => {
            if (response.status === SUCCESS) {

            }
        });
    };

    const handleChange = (field,content) => {
        setsummary(content.text)
    }


    const current_version_id = id;

    const {isAuthenticated,user,versions,current_product} = props;


    return (
        <div className={"page-container"}>

            {Boolean(version) && <div className={''}>
                <div className={'row no-wrap spacing-0'}>
                    <div className="col-lg-3 d-none d-lg-block sidebar">
                        <div className={''}>
                            <div>
                                <div className={'p-3'}>
                                    <VersionList versions={versions} current_product={current_product} current_version={version} current_version_status={status} />
                                </div>
                                <ul className={'list-group border-0 list-group-toc'}>

                                    <LinkManageVersion />
                                    {
                                        sub && map(sub, function (key, version) {
                                            return (
                                                <li key={key} className={'list-group-item'}>
                                                    <Scroll className={`nav-link level-1 toc`}  scroll={(el)=>scrollWidthOffset(el)}   to={{hash: `#${validUrl(version.version)}`,search: `?vid=${current_version_id}&pid=${current_product.id}`}}>
                                                        {version.version}
                                                        <span className={`text-${version.status} text-uppercase`}> {version.status} </span>
                                                    </Scroll>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-9 col-12 p-0 p-lg-3 page-content border-left'}>

                        <div className={'container'}>
                            <div className={'d-flex align-items-center'}>
                                <h1 className={'title   mr-auto'}>
                                    Release Notes

                                    <div className={'d-block d-lg-none'}>
                                        <VersionList versions={versions} current_product={current_product} current_version={version} current_version_status={status} />
                                    </div>

                                </h1>

                                <div> {isAuthenticated && user.staff &&
                                    <Button color="transparent" onClick={()=> setUpdatemode(!updatemode) }> <FontAwesomeIcon icon={faPencil} /> </Button>
                                }</div>
                            </div>

                            {Boolean(version) && <div>
                                {
                                    <div>

                                        {updatemode ? <div>

                                            <h5>Summary</h5>
                                            <MdEditor
                                                value={summary}
                                                ref={editor}
                                                style={{height: '400px'}}
                                                renderHTML={(text) => mdParser.render(text)}
                                                onChange={(e)=> { setsummary(e.text)} }
                                            />

                                            <h5>Feature List</h5>
                                            <MdEditor
                                                value={featurelist}
                                                ref={editor}
                                                style={{height: '400px'}}
                                                renderHTML={(text) => mdParser.render(text)}
                                                onChange={(e)=> { setfeaturelist(e.text)} }
                                            />

                                            <div className={'center mt-3'}>
                                                <Button color="primary" onClick={()=>onSubmit(id,summary,featurelist,configuration)}>Save</Button>
                                            </div>

                                        </div> : <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: mdParser.render(summary)
                                                }}></div>

                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: mdParser.render(featurelist)
                                                }}></div>
                                        </div>}



                                        <div>
                                            {
                                                sub && map(sub, function (key, version) {
                                                    return (<div key={key}  className={'bottom-space mt-4'}>
                                                        <div>
                                                            <div className={'d-flex'} >
                                                                <h3 className={'title m-0'}  id={`${validUrl(version.version)}`}>
                                                                    {version.version}
                                                                </h3>
                                                                <div className={'ml-3'}>
                                                                    <Badge className={`badge badge-${version.status} text-uppercase`}> {version.status} </Badge>
                                                                    {version.releasedate && <small> on {version.releasedate} </small>}
                                                                </div>
                                                            </div>


                                                            {updatemode ? <div>

                                                                <h5>Summary</h5>
                                                                <MdEditor
                                                                    value={version.summary}
                                                                    ref={editor}
                                                                    style={{height: '400px'}}
                                                                    renderHTML={(text) => mdParser.render(text)}
                                                                    onChange = {(e) => {
                                                                        setsub((prevState)=>{
                                                                            return {...prevState,[key]:{...prevState[key],summary:e.text}}
                                                                        })
                                                                        setfeaturelist(e.text)
                                                                        //that.setState({...that.state,sub:{...that.state.sub,[key]:{...that.state.sub[key],summary:e.text}}})
                                                                    }}
                                                                />

                                                                <h5>Feature List</h5>
                                                                <MdEditor
                                                                    value={version.featurelist}
                                                                    ref={editor}
                                                                    style={{height: '400px'}}
                                                                    renderHTML={(text) => mdParser.render(text)}
                                                                    onChange = {(e) => {
                                                                        setsub((prevState)=>{
                                                                            return {...prevState,[key]:{...prevState[key],featurelist:e.text}}
                                                                        })
                                                                        setfeaturelist(e.text)
                                                                        //that.setState({...that.state,sub:{...that.state.sub,[key]:{...that.state.sub[key],featurelist:e.text}}})
                                                                    }}
                                                                />

                                                                <div className={'center mt-3'}>
                                                                    <Button color="primary" onClick={()=>onSubmit(version.id,version.summary,version.featurelist)}>Save</Button>
                                                                </div>

                                                            </div> : <div>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: mdParser.render(version.summary)
                                                                    }}></div>

                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: mdParser.render(version.featurelist)
                                                                    }}></div>
                                                            </div>}



                                                            {version.feature && Boolean(version.feature.length) && <div className={'featurelist'} name={`feature-${key}`}>
                                                                <h4 className={'p-2'}> Thing that makes something better</h4>
                                                                <ul>
                                                                    {
                                                                        map(version.feature, function (key, feature) {
                                                                            return (<li key={key}> <NavLink tag={Link} to={`/${validUrl(current_product.title)}/featuredetail/${validUrl(feature.title)}/?fid=${feature.id}&pid=${current_product.id}`}>{feature.title}  <FontAwesomeIcon icon={feature.type==='feature'? faLightbulbOn : faBug } color={'#999'}   /> </NavLink> </li>)
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>}


                                                        </div>
                                                    </div>)
                                                })
                                            }
                                        </div>

                                        {updatemode ? <div>

                                            <h5>Configuration</h5>
                                            <MdEditor
                                                value={configuration}
                                                ref={editor}
                                                style={{height: '400px'}}
                                                renderHTML={(text) => mdParser.render(text)}
                                                onChange={(e)=> { setconfiguration(e.text)} }
                                            />

                                        </div> : <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: mdParser.render(configuration)
                                                }}></div>
                                        </div>}



                                    </div>
                                }
                            </div>}
                        </div>
                    </div>

                </div>
            </div>}

        </div>
    );


}

const mapStateToProps = (state) => {
    return {
        versions: state.appApiData.version,
        isAuthenticated: state.authentication.isAuthenticated,
        current_product: state.appApiData.current_product,
        user: state.authentication.authDetails.user,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setReleases: (release) => dispatch(setReleases(release)),
    setDialog: (dialog) => dispatch(setDialog(dialog)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Index);
